<template>
  <v-form v-model="formIsValid" ref="form" @submit.prevent="submitForm">
    <v-row class="flex-column">
      <v-col>
        <v-select
          v-model="criteria.value"
          :items="filterParams.options"
          :rules="[$data.$globalRules.required]"
          :multiple="filterParams.multiple"
          :chips="filterParams.multiple"
          :clearable="filterParams.multiple"
          :label="
            filterParams.multiple ? 'Selecciona uno o más elementos' : 'Selecciona un elemento'
          "
        />
      </v-col>
      <v-col class="text-right">
        <slot name="actions"></slot>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
export default {
  data() {
    return {
      criteria: {
        operator: "e",
        value: null,
      },
      formIsValid: null,
    };
  },
  props: {
    filterParams: {
      type: Object,
      default: () => {},
      required: false,
    },
  },
  methods: {
    submitForm() {
      this.$refs.form.validate();
      if (this.formIsValid) {
        this.$emit("submitForm", this.criteria);
      }
    },
  },
};
</script>

<style scoped></style>
